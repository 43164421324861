
import { Options, Vue } from "vue-class-component";
import Toaster from "../../helpers/Toaster";
import scheduleTxn from "../../service/ScheduleService";
import {
  branchList,
  assignScheduleProp,
  AllScheduleList,
  StoreSchedules
} from "../schedulelist/IScheduleList";
import { camelCase } from "lodash";
import moment from "moment";

@Options({
  components: {},
})
export default class ScheduleList extends Vue {
  private viewTabs = "add-schedule";
  private scheduleTxn;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: " Schedule List", to: "/store/schedule-list" },
  ];
  private toast;
  private branchList: Array<branchList> = [];
  private allScheduleList: Array<AllScheduleList> = [];
  private storeSchedules: Array<StoreSchedules> = [];
  private scheduleList = [];
  private dialogTile = "Add New Schedule";
  private scheduleName = "";
  private scheduleID = "";
  private submitted = false;
  private assignScheduleProp: assignScheduleProp = {
    schedule: [],
    type: [],
    store: [],
  };
  

  private scheduleType = [
    { key: "specific_branch", value: "Specific Stores" },
    { key: "all_branch", value: "All Stores" },
  ];
  created() {
    this.scheduleTxn = new scheduleTxn();
    this.toast = new Toaster();
  }

  //ADD SCHEDULE NAME
  addSchedule(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.scheduleName.trim()) {
      this.scheduleTxn.addSchedule(this.scheduleName).then((res) => {
        //SHOW NOTIFICATION
        this.toast.handleResponse(res);
        this.scheduleName = "";
        this.submitted = false;
      });
    }
  }

  //SWITCH TABS
  activePanel(value) {
    this.viewTabs = value;
    if (value == "assign-schedule") {
      this.loadAssignScheduleData();
    } else if (value == "available-schedule") {
      this.getScheduleList();
    }
    else if (value == "add-schedule") {
      this.dialogTile = 'Add New Schedule';
      this.scheduleName = '';
      this.scheduleID = '';
    }
  }

  //ASSIGN SCHEDULE TO STORES
  assignSchedule(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.assignScheduleProp.schedule.length != 0) {
      this.scheduleTxn.assignSchedule(this.assignScheduleProp).then((res) => {
        //SHOW NOTIFICATION
        this.toast.handleResponse(res);
        this.submitted = false;
      });
    }
  }

  //ASSIGN SCHEDULE TO STORES
  loadAssignScheduleData() {
    this.scheduleTxn.getAssignScheduleData().then((res) => {
      const data = this.camelizeKeys(res);
      this.branchList = data.branchList;
      this.scheduleList = data.scheduleList;
    });
  }
  
  makeDefault(scheduleID,storeCode) {
    this.scheduleTxn.makeDefault(scheduleID,storeCode).then((res) => {
      this.toast.handleResponse(res);
      this.openScheduleStores(scheduleID);
    });
  }

  //GET SCHEDULE LIST
  getScheduleList() {
    this.scheduleTxn.getScheduleList().then((res) => {
      const data = this.camelizeKeys(res);
      this.allScheduleList = data;
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  openScheduleStores(scheduleID)
  {
    this.scheduleTxn.getScheduleStores(scheduleID).then((res) => {
      const data = this.camelizeKeys(res);
      this.storeSchedules = data;
    });
  }

  changeScheduleStatus(scheduleID,status)
  {
    this.scheduleTxn.changeScheduleStatus(scheduleID,status).then((res) => {
      this.toast.handleResponse(res);
      this.getScheduleList();
    });
  }

  editSchedulePost(e)
  {
    e.preventDefault();
    this.submitted = true;

    if(this.scheduleID != '' &&  this.scheduleName != '')
    {
      this.scheduleTxn.editSchedule(this.scheduleID,this.scheduleName).then((res) => {
        this.toast.handleResponse(res);
        this.viewTabs = 'available-schedule';
        this.getScheduleList();
         this.submitted = false;
      });
    }
  }

  removeStoreSchedule(scheduleID,code)
  {
    this.scheduleTxn.removeStoreSchedule(scheduleID,code).then((res) => {
      this.toast.handleResponse(res);
      this.viewTabs     = "add-schedule";
      this.openScheduleStores(scheduleID);
    });
  }

  editScheduleName(id,scheduleName)
  {
    this.dialogTile = 'Edit Schedule Name';
    this.scheduleName = scheduleName;
    this.scheduleID   = id;
    this.viewTabs     = "add-schedule";
  }
}
