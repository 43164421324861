<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <Button
            @click="activePanel('add-schedule')"
            class="p-button-success p-mr-1"
            label="Add New Schedule"
            icon="pi pi-plus"
          />
          <Button
            @click="activePanel('assign-schedule')"
            class="p-button-primary p-mr-1"
            label="Assign Schedule"
            icon="pi pi-copy"
          />
          <Button
            @click="activePanel('available-schedule')"
            class="p-button-info p-mr-1"
            label="Schedule Available at Store"
            icon="pi pi-filter"
          />
        </template>
      </Toolbar>
      <div class="card p-mt-2">
        <Splitter>
          <SplitterPanel class="p-p-2">
            <h5><i class="pi pi-file-o"></i> Manage Store Schedule List</h5>
             <div class="table-responsive schedule-stores">
                <table class="table table-striped table-bordered table-style">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Schedule Name</th>
                      <th>Assigned Store</th>
                      <th>Default</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(store,index) in storeSchedules" :key="store">
                      <td>
                        {{index+1}}
                      </td>
                      <td>
                        {{ store.scheduleName }}
                      </td>
                      <td>
                        {{ store.mall }} ({{ store.code }})
                      </td>
                      <td>
                        <span v-if="store.defaultSchedule == store.id ">
                          <i class="pi pi-check-circle" style="fontSize: 1rem"></i>
                        </span>
                      </td>
                      <td class="text-center">
                          <Button
                            class="p-button-primary p-p-1 p-mr-1"
                            label="Default"
                            @click="makeDefault(store.id,store.code)"
                          />
                          <Button
                            class="p-button-danger  p-p-1"
                            label="Remove"
                            @click="removeStoreSchedule(store.id,store.code)"
                          />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </SplitterPanel>
          <SplitterPanel class="p-p-2">
            <div class="add-schedule" v-if="viewTabs == 'add-schedule'">
              <h5><i class="pi pi-plus-circle"></i> {{dialogTile}}</h5>
              <div class="p-field">
                <label for="region" class="d-block">Schedule Name:</label>
                <InputText
                  id="region"
                  v-model.trim="scheduleName"
                  required="true"
                  class="w-100"
                  :class="{ 'p-invalid': submitted && !scheduleName }"
                />
                <small class="p-invalid" v-if="submitted && !scheduleName"
                  >Schedule Name is required.</small
                >
              </div>
              <div class="p-field">
                <Button
                 v-if="scheduleID == ''"
                  @click="addSchedule"
                  type="submit"
                  class="p-button-primary"
                  label="Save Schedule"
                  icon="pi pi-save"
                />
                <Button
                  v-if="scheduleID != ''"
                  @click="editSchedulePost"
                  type="submit"
                  class="p-button-primary"
                  label="Update Schedule"
                  icon="pi pi-pencil"
                />
              </div>
            </div>
            <!-- .assign-schedule -->
            <div class="assign-schedule" v-if="viewTabs == 'assign-schedule'">
              <h5><i class="pi pi-copy"></i> Assign Schedule to Stores</h5>
              <div class="p-field">
                <label for="province" class="d-block">Choose Schedule:</label>
                <Dropdown
                  v-model="assignScheduleProp.schedule"
                  :options="scheduleList"
                  optionLabel="scheduleName"
                  class="w-100"
                  required="true"
                  :filter="true"
                  :class="{
                    'p-invalid':
                      submitted && assignScheduleProp.schedule.length == 0,
                  }"
                />
                <small
                  class="p-invalid"
                  v-if="submitted && assignScheduleProp.schedule.length == 0"
                  >Schedule Name is required.</small
                >
              </div>
              <div class="p-field">
                <label for="province" class="d-block">Schedule Type:</label>
                <Dropdown
                  v-model="assignScheduleProp.type"
                  :options="scheduleType"
                  optionLabel="value"
                  class="w-100"
                />
              </div>
              <div class="p-field">
                <label for="filterStore"
                  >Stores: (Select only when specific Stores otherwise ignore it
                  if all Stores):</label
                >
                <MultiSelect
                  v-model="assignScheduleProp.store"
                  :options="branchList"
                  emptyFilterMessage="No Store found"
                  scrollHeight="300px"
                  display="chip"
                  class="w-100"
                >
                <template #value="slotProps">
                  <div
                    class="country-item country-item-value"
                    v-for="option of slotProps.value"
                    :key="option.code">
                    <div>Store {{ option.mall }} | Code {{ option.code }}</div>
                  </div>
                <template v-if="!slotProps.value || slotProps.value.length === 0"> Select Stores </template>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>
                  Store {{ slotProps.option.mall }} | Code
                  {{ slotProps.option.code }}
                </div>
              </div>
            </template>
          </MultiSelect>
          <small
            class="p-invalid"
            v-if="
              submitted &&
              assignScheduleProp.type.key == 'specific_branch' &&
              assignScheduleProp.store.length == 0
            "
            >Stores is required.</small
                >
              </div>
              <div class="p-field">
                <Button
                  @click="assignSchedule"
                  type="submit"
                  class="p-button-primary"
                  label="Assign Schedule"
                  icon="pi pi-save"
                />
              </div>
            </div>
            <!-- .assign-schedule-->
            <div
              class="available-schedule"
              v-if="viewTabs == 'available-schedule'"
            >
              <h5><i class="pi pi-filter"></i> Schedule Available at Store</h5>
              <div class="table-responsive schedule-stores">
                <table class="table table-striped table-bordered table-style">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Schedule Name</th>
                      <th>Created By</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="sch in allScheduleList" :key="sch">
                      <td>
                        <label class="btn btn-secondary">
                          <input
                            type="radio"
                            style="width: 15px; height: 15px"
                            name="choose"
                            id="choose"
                            @click="openScheduleStores(sch.id)"
                          />
                          Choose
                        </label>
                      </td>
                      <td>
                        {{ sch.scheduleName }}
                      </td>
                      <td>
                        {{ sch.mall }}
                      </td>
                      <td>
                        {{ formatDate(sch.createdDate) }}
                      </td>
                      <td class="text-center align-middle">
                        <span class="p-buttonset">
                          <Button
                            class="p-button-primary p-button-rounded"
                            icon="pi pi-pencil"
                            @click="editScheduleName(sch.id,sch.scheduleName)"
                          />
                          <Button
                            v-if="sch.status == 'Active'"
                            class="p-button-danger p-button-rounded"
                            icon="pi pi-eye-slash"
                            @click="changeScheduleStatus(sch.id,'Inactive')"
                          />
                          <Button
                            v-if="sch.status == 'Inactive'"
                            class="p-button-success p-button-rounded"
                            icon="pi pi-eye"
                            @click="changeScheduleStatus(sch.id,'Active')"
                          />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </SplitterPanel>
        </Splitter>
      </div>
      <!-- .card-->
    </div>
  </section>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Toaster from "../../helpers/Toaster";
import scheduleTxn from "../../service/ScheduleService";
import {
  branchList,
  assignScheduleProp,
  AllScheduleList,
  StoreSchedules
} from "../schedulelist/IScheduleList";
import { camelCase } from "lodash";
import moment from "moment";

@Options({
  components: {},
})
export default class ScheduleList extends Vue {
  private viewTabs = "add-schedule";
  private scheduleTxn;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: " Schedule List", to: "/store/schedule-list" },
  ];
  private toast;
  private branchList: Array<branchList> = [];
  private allScheduleList: Array<AllScheduleList> = [];
  private storeSchedules: Array<StoreSchedules> = [];
  private scheduleList = [];
  private dialogTile = "Add New Schedule";
  private scheduleName = "";
  private scheduleID = "";
  private submitted = false;
  private assignScheduleProp: assignScheduleProp = {
    schedule: [],
    type: [],
    store: [],
  };
  

  private scheduleType = [
    { key: "specific_branch", value: "Specific Stores" },
    { key: "all_branch", value: "All Stores" },
  ];
  created() {
    this.scheduleTxn = new scheduleTxn();
    this.toast = new Toaster();
  }

  //ADD SCHEDULE NAME
  addSchedule(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.scheduleName.trim()) {
      this.scheduleTxn.addSchedule(this.scheduleName).then((res) => {
        //SHOW NOTIFICATION
        this.toast.handleResponse(res);
        this.scheduleName = "";
        this.submitted = false;
      });
    }
  }

  //SWITCH TABS
  activePanel(value) {
    this.viewTabs = value;
    if (value == "assign-schedule") {
      this.loadAssignScheduleData();
    } else if (value == "available-schedule") {
      this.getScheduleList();
    }
    else if (value == "add-schedule") {
      this.dialogTile = 'Add New Schedule';
      this.scheduleName = '';
      this.scheduleID = '';
    }
  }

  //ASSIGN SCHEDULE TO STORES
  assignSchedule(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.assignScheduleProp.schedule.length != 0) {
      this.scheduleTxn.assignSchedule(this.assignScheduleProp).then((res) => {
        //SHOW NOTIFICATION
        this.toast.handleResponse(res);
        this.submitted = false;
      });
    }
  }

  //ASSIGN SCHEDULE TO STORES
  loadAssignScheduleData() {
    this.scheduleTxn.getAssignScheduleData().then((res) => {
      const data = this.camelizeKeys(res);
      this.branchList = data.branchList;
      this.scheduleList = data.scheduleList;
    });
  }
  
  makeDefault(scheduleID,storeCode) {
    this.scheduleTxn.makeDefault(scheduleID,storeCode).then((res) => {
      this.toast.handleResponse(res);
      this.openScheduleStores(scheduleID);
    });
  }

  //GET SCHEDULE LIST
  getScheduleList() {
    this.scheduleTxn.getScheduleList().then((res) => {
      const data = this.camelizeKeys(res);
      this.allScheduleList = data;
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  openScheduleStores(scheduleID)
  {
    this.scheduleTxn.getScheduleStores(scheduleID).then((res) => {
      const data = this.camelizeKeys(res);
      this.storeSchedules = data;
    });
  }

  changeScheduleStatus(scheduleID,status)
  {
    this.scheduleTxn.changeScheduleStatus(scheduleID,status).then((res) => {
      this.toast.handleResponse(res);
      this.getScheduleList();
    });
  }

  editSchedulePost(e)
  {
    e.preventDefault();
    this.submitted = true;

    if(this.scheduleID != '' &&  this.scheduleName != '')
    {
      this.scheduleTxn.editSchedule(this.scheduleID,this.scheduleName).then((res) => {
        this.toast.handleResponse(res);
        this.viewTabs = 'available-schedule';
        this.getScheduleList();
         this.submitted = false;
      });
    }
  }

  removeStoreSchedule(scheduleID,code)
  {
    this.scheduleTxn.removeStoreSchedule(scheduleID,code).then((res) => {
      this.toast.handleResponse(res);
      this.viewTabs     = "add-schedule";
      this.openScheduleStores(scheduleID);
    });
  }

  editScheduleName(id,scheduleName)
  {
    this.dialogTile = 'Edit Schedule Name';
    this.scheduleName = scheduleName;
    this.scheduleID   = id;
    this.viewTabs     = "add-schedule";
  }
}
</script>

<style scoped>
.table-style td,
th {
  text-align: center;
  padding: 0;
  padding-top: 5px;
  margin: 0;
}


.schedule-stores
{
  height: 80vh;
  min-height: 80vh;
  overflow-y: scroll;
}
</style>
